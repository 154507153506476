import store from "@/store";
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

export const totalCommon = {
  data() {
    return {
      shortcuts: [
        {
          text: '最近一年',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            return [start, end]
          },
        },
        {
          text: '最近90天',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
        {
          text: '最近30天',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: '最近一周',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: '前天',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 2)
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 2);
            return [start, end]
          },
        },
        {
          text: '昨天',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
            return [start, end]
          },
        },
        {
          text: '今天',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
            return [start, end]
          },
        },
        {
          text: '明天',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(end.getTime() + 3600 * 1000 * 24 * 1)
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 1)
            return [start, end]
          },
        },
        {
          text: '后天',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(end.getTime() + 3600 * 1000 * 24 * 2)
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 2)
            return [start, end]
          },
        },
        {
          text: '未来一周',
          value: () => {
            const end = new Date()
            const start = new Date()
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: '未来30天',
          value: () => {
            const end = new Date()
            const start = new Date()
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: '未来90天',
          value: () => {
            const end = new Date()
            const start = new Date()
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
        {
          text: '未来一年',
          value: () => {
            const end = new Date()
            const start = new Date()
            end.setTime(start.getTime() + 3600 * 1000 * 24 * 365)
            return [start, end]
          },
        },
      ],
      totalYesNo: [{ 'key': 0, 'value': '否' }, { 'key': 1, 'value': '是' }],
      totalState: [{ 'key': 0, 'value': '未确认' }, { 'key': 1, 'value': '已确认' }, { 'key': 2, 'value': '已核准' }],
      confirmedOptions: [{ 'key': 0, 'value': '未执行' }, { 'key': 1, 'value': '正在执行' }, { 'key': 2, 'value': '已执行完毕' }, { 'key': 3, 'value': '部分中止' }, { 'key': 4, 'value': '完全中止' }]
    }
  },

  methods: {
    hasAuth(perm) {
      let perms = JSON.parse(sessionStorage.getItem("perms"));
      return perms.indexOf(perm) >= 0
    },

    exportPdf(dom, outFileName) {
      console.log(dom, outFileName)
      var element = document.querySelector(dom); // 这个dom元素是要导出pdf的div容器
      console.log(element)
      // element.style.height = param.height
      setTimeout(() => {
        html2Canvas(element, {
          allowTaint: true,
          scale: 2,
          background: "#F5F5F5"
        }).then(function (canvas) {
          var contentWidth = canvas.width;
          var contentHeight = canvas.height;

          // 一页pdf显示html页面生成的canvas高度;
          var pageHeight = contentWidth / 592.28 * 841.89;
          // 未生成pdf的html页面高度
          var leftHeight = contentHeight;
          // 页面偏移
          var position = 0;
          // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
          var imgWidth = 595.28;
          var imgHeight = 592.28 / contentWidth * contentHeight;

          var pageData = canvas.toDataURL('image/jpeg', 1.0);

          var pdf = new JsPDF('', 'pt', 'a4');

          // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
          // 当内容未超过pdf一页显示的范围，无需分页
          if (leftHeight < pageHeight) {
            pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
              leftHeight -= pageHeight;
              position -= 841.89;
              // 避免添加空白页
              if (leftHeight > 0) {
                pdf.addPage();
              }
            }
          }
          pdf.save(outFileName + '.pdf');
          console.log("执行完毕")
        });
        // element.style.height = param.minHeight
      }, 0);
    },

    getPdf(dom, title) {
      // loading = true;
      // console.log(loading);
      html2Canvas(document.querySelector(dom), {
        allowTaint: true,
        taintTest: true,
        logging: true,
        useCORS: true,
        dpi: window.devicePixelRatio * 4, //将分辨率提高到特定的DPI 提高四倍
        scale: 4, //按比例增加分辨率
      }).then((canvas) => {
        var pdf = new JsPDF('', 'pt', 'a4');
        //   var pdf = new jsPDF('p', 'mm', 'a4'); //A4纸，纵向
        var ctx = canvas.getContext('2d'),
          a4w = 190,
          a4h = 272, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
          imgHeight = Math.floor((a4h * canvas.width) / a4w), //按A4显示比例换算一页图像的像素高度
          renderedHeight = 0;

        while (renderedHeight < canvas.height) {
          var page = document.createElement('canvas');
          page.width = canvas.width;
          page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页

          //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
          page
            .getContext('2d')
            .putImageData(
              ctx.getImageData(
                0,
                renderedHeight,
                canvas.width,
                Math.min(imgHeight, canvas.height - renderedHeight),
              ),
              0,
              0,
            );
          pdf.addImage(
            page.toDataURL('image/jpeg', 1.0),
            'JPEG',
            10,
            10,
            a4w,
            Math.min(a4h, (a4w * page.height) / page.width),
          ); //添加图像到页面，保留10mm边距

          renderedHeight += imgHeight;
          if (renderedHeight < canvas.height) {
            pdf.addPage(); //如果后面还有内容，添加一个空页
          }
          // delete page;
        }
        //保存文件
        pdf.save(title + '.pdf');
        // loading = false;
        //   console.log(loading);
      });
    },

    downloadDocument(document_path, document_data) {
      console.log(document_path, document_data)
      // var pdf = new JsPDF();
      // pdf.text(document_data)
      // pdf.save("123.pdf");
      // const fs = require("fs");
      // const blob = new Blob([document_data], 
      // { type: 'image/jpeg'})

      // saveAs(blob,document_path)
      //   let blob = new Blob([document_data], {
      //     type: "image/png"
      // });
      // saveAs(blob, "1111.png");


      let url = window.URL.createObjectURL(new Blob([document_data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", document_path);
      document.body.appendChild(link);
      link.click();

    },

    getLabelFromValue(totalOptionsList, attribute_id, attribute_label, attribute_value) {
      try {
        var result = totalOptionsList.find(item => item[attribute_id] == attribute_value);
        if (result != undefined && attribute_label in result) {
          return result[attribute_label];
        }
        return attribute_value;
      } catch (error) {
        return '';
      }

    },
    /**
     * @function 根据当前时间生成导出的文件名
     * @param {} 无参数
     * @return {String} result 文件名
     * @example getOutputName(true)
     */
    getOutputName() {
      let datetime = new Date();
      let year = datetime.getFullYear();
      let month = datetime.getMonth();
      let date = datetime.getDate();
      let hour = datetime.getHours();
      let minute = datetime.getMinutes();
      let second = datetime.getSeconds();

      let result = year +
        '' +
        ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
        '' +
        ((date + 1) < 10 ? '0' + date : date) +
        '' +
        ((hour + 1) < 10 ? '0' + hour : hour) +
        '' +
        ((minute + 1) < 10 ? '0' + minute : minute) +
        '' +
        ((second + 1) < 10 ? '0' + second : second);
      return result;
    },
    /**
     * @function 过滤字典数组指定项的重复值
     * @param {List} total_data 字典数组
     * @param {List} custom_filters 指定项
     * @return {Object} 指定项去重后的值
     * @example tableFilter(totalProduct.value, ['product_no', 'category', 'product_name'])
     */
    tableFilter(total_data, custom_filters) {
      let filters = {};
      custom_filters.forEach(filter_key => {
        filters[filter_key] = [];
      })

      total_data.forEach(item => {
        for (let key in filters) {
          if (!filters[key].find(i => i.text == item[key])) {
            filters[key].push({ text: item[key], value: item[key] });
          }
        }
      })
      return filters;
    },
    /**
     * @function 判断两个值是否相等，此处用来判断当前页是否发生变化
     * @param {Object} source 待比较的第一个值
     * @param {Object} comparison 待比较的第二个值
     * @return {Boolean} 若两个值相等，则返回true；否则返回false
     * @example isObjectChanged(pageInfo.value, pageInfoBAK.value)
     */
    isObjectChanged(source, comparison) {
      const _source = JSON.stringify(source);
      const _comparison = JSON.stringify({ ...source, ...comparison });
      return _source !== _comparison;
    },
    
    /**
     * @function 通用 1是 0否
     * @param {Number} val 值
     * @return {String} val 值对应的文本
     * @example getYesNo(scope.row.clearCustom)
     */
    getYesNo(val) {
      switch (val) {
        case 0:
          return '否';
        case 1:
          return '是';
        default:
          return '';
      }
    },
    /**
     * @function 将订单flag的值转换为对应的文本
     * @param {Number} state 订单的flag值
     * @return {String} flag值对应的文本
     * @example getStateText(scope.row.flag)
     */
    getStateText(state) {
      switch (state) {
        case 0:
          return '未确认';
        case 1:
          return '已确认';
        case 2:
          return '已核准';
        case 3:
          return '已禁用';
        default:
          return '';
      }
    },
    getStateTextForIndex(state1,state2) {
      switch (state1) {  //flag
        case 0:
          return '待确认';
        case 1:
          return '待核准';
        case 2:
          switch (state2) {  //confirmed
            case 0:
              return '待执行(待通知财务)';
            case 1:
              return '正在执行(待通知财务)';
            case 2:
              return '待通知财务';
            default:
              return '';
          }
        default:
          return '';
      }
    },
    /**
     * @function 设置组合条件中的默认日期范围，此处为当月1号至当日
     * @param {} 无参数
     * @return {} 无返回值
     * @example defaultDate()
     */
    defaultDate() {
      let date = new Date();
      let year = date.getFullYear().toString();
      let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString();
      let da = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString();
      let end = year + '-' + month + '-' + da;
      let beg = year + '-' + month + '-01';
      return [beg, end];
    },
    /**
     * @function 过滤掉字典中值为null或者空字符串的键
     * @param {Object} obj 待操作的字典
     * @return {Object} _newPar 去除值为null或者空字符串后的字典
     * @example filterParams(receiptPurchaseFilter.value.equals)
     */
    filterParams(obj) {
      var _newPar = {};
      for (var key in obj) {
        if ((obj[key] === 0 || obj[key] === false || obj[key]) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
          _newPar[key] = obj[key];
        }
      }
      return _newPar;
    },

    /**
    * @function 格式化指定时间
    * @param {String} strTime 待格式化的时间
    * @param {Boolean} withTime 若为true，则格式化后的时间将带时分秒；若为false，则格式化后的时间仅有日期
    * @return {String} 格式化后的时间字符串 
    * @example dateStrFormat(new Date(), true)
    */
    dateStrFormat(strTime, withTime = false) {
      if (strTime != null && strTime != '' && strTime != undefined) {
        let datetime = new Date(strTime);
        let year = datetime.getFullYear();
        let month = datetime.getMonth();
        let date = datetime.getDate();
        let hour = datetime.getHours();
        let minute = datetime.getMinutes();
        let second = datetime.getSeconds();
        let result = year +
          '-' +
          ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
          '-' +
          ((date + 1) < 10 ? '0' + date : date)
        let result2 = year +
          '-' +
          ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
          '-' +
          ((date + 1) < 10 ? '0' + date : date) +
          ' ' +
          ((hour + 1) < 10 ? '0' + hour : hour) +
          ':' +
          ((minute + 1) < 10 ? '0' + minute : minute) +
          ':' +
          ((second + 1) < 10 ? '0' + second : second);
        if (withTime) {
          return result2;
        } else {
          return result;
        }
      }
      return '';
    },

    getConfirmedText(confirmed, confirmedOptions) {
      let res = confirmedOptions.find(item => {
        return item.key == confirmed
      })
      if (res == undefined) {
        return '';
      } else {
        return res.value;
      }
    },

    //20230702 lpj：编写isModification函数，控制字段能否事前修改、事后修改
    isModification(pageType, flag, beforehand = true, afterhand = false, editable = true) {
      if (typeof pageType == 'string') {
        if (pageType.includes('Add')) {
          return beforehand;
        }
        if (pageType.includes('Edit') && flag == 0) {
          return beforehand && editable;
        } else if ( pageType.includes('Edit') && (flag == 2 || flag == 3) ) {   // flag == 3 ONLY Product
          return afterhand;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    //20230702 lpj：编写isModificationClass函数，控制字段事前修改、事后修改的样式
    isModificationClass(pageType, flag, beforehandClass = '', afterhandClass = '') {
      if (typeof pageType == 'string') {
        if (pageType.includes('Add') || (pageType.includes('Edit') && flag == 0)) {
          return beforehandClass;
        } else if ((pageType.includes('Edit') && flag == 2)) {
          return afterhandClass;
        } else {
          return '';
        }
      } else {
        return '';
      }
    },

    //20230708 lpj：从详情页链接至其他页面
    linkJump(pre_path, path_type, name, msg, router, effe = false) {
      let item = {
        'path': pre_path + path_type, 'name': name, 'icon': 'component'
      };

      store.commit('ADD_TABS', item);

      router.push({
        path: item.path,
        state: {
          type: path_type,
          msg: msg,
          situation: JSON.stringify({ 'index': 0, 'pageSize': 1, 'totalPage': 1 }),
          effective: effe
        }
      });
    },
    changeKeyLabel(obj, old_name, new_name) {
        if (old_name.length == new_name.length) {
            let str_obj = JSON.stringify(obj)
            for (let i = 0; i < old_name.length; i++){
              str_obj = str_obj.replace(new RegExp(old_name[i], 'g'), new_name[i])
            }
            return JSON.parse(str_obj)
        }else{
            return obj
        }
    },
    validateRandomPassword(length){
      let passwd='';
      do {
        passwd=randPassword(length);
      } while(!validate(passwd));
      return passwd;
    }
    

  }
}

function randPassword(length, passwordArray) {
  length = length || 6;
  // 密码串----默认 大写字母 小写字母 数字
  passwordArray = passwordArray || ['ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz', '1234567890', '!@#$%&*()'];
  var password = '';
  // 随机生成开始字符串
  var startIndex = parseInt(Math.random() * (length));
  var randIndex = [];
  for (var i = 0; i < length; i++) {
    // 创建数组，用于取随机位置  [0,1,2,3,4,5,....]
      randIndex.push(i);
  }
  for (var i = 0; i < length; i++) {
      // 根据随机数组生成随机位置
      var r = parseInt(Math.random() * (randIndex.length));
      var num = randIndex[r] + startIndex;
      // 根据随机值取余数
      var randRemainder = num % passwordArray.length;
      // 当前密码串【大写字母，小写字母，数字等】
      var currentPassword = passwordArray[randRemainder];
      // 根据当前密码串长度取随机数
      var index = parseInt(Math.random() * (currentPassword.length));
      // 获取随机字符串
      var str = currentPassword.substr(index, 1);
      // 删除随机数组中已经使用的值
      randIndex.splice(r, 1);
      password += str;
  }
  return password;
}


function validate(value) {
  if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[._~!@#$^&*])[A-Za-z0-9._~!@#$^&*]{8,16}$/g.test(value)) {
      return true
  } else {
      return false
  }
}
